.collapse {
    display: flex;
    width: 100%;

    cursor: pointer;
}

.collapse .collapse-icon-holder {
    min-width: 16px;
}

.collapse .collapse-content {
    transition: max-height 0.15s ease-in-out;
    overflow: hidden;
}

.collapse .collapse-icon {
    transition: transform 0.15s ease-in-out;
    transform: rotate(90deg);
}

.collapse.hidden .collapse-icon {
    transform: rotate(00deg);
}
