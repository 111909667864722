.login-inner {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 4px;
    width: 400px;
}

.login-button {
    width: 50%;
    margin-top: 8px;
    margin-left: auto;
    margin-right: auto;
}

.login-error-message {
    background-color: rgba(255, 0, 0, 0.1);
    padding: 8px;
    margin-top: 4px;
    margin-bottom: 4px;
    border-radius: 4px;
}