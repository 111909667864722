.center-modal {
    display: flex;

    width: 100%;
    height: 100%;

    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.center-modal-logo {
    margin-bottom: 32px;
    width: 400px;
}


.center-modal-inner {
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius-large);
    box-shadow: var(--box-shadow-large);

    min-width: 400px;

    background-color: white;
}
