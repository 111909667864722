.dashboard-outer {
    display: grid;
    height: 100%;
    width: 100%;

    grid-template-areas:
        "header header"
        "menu   main"
        "footer footer";

    grid-template-columns: 240px 1fr;
    grid-template-rows: auto 1fr auto;
}

.dashboard-header {
    grid-area: header;
    background-color: green;
}

.dashboard-sidebar {
    grid-area: menu;
    background-color: white;

    padding-top: 32px;
    box-shadow: var(--box-shadow-large);
}

.dashboard-main {
    grid-area: main;
    margin: 32px;
    padding: 16px;
    background-color: white;

    box-shadow: var(--box-shadow-large);
}

.dashboard-footer {
    grid-area: footer;

    background-color: blueviolet;
}